























import { Component, Vue } from 'vue-property-decorator';
import LoadingDataCard from '@/vue-app/components/home-dashboard/LoadingDataCard.vue';
import MyPersonalBalanceViewModelV2 from '@/vue-app/view-models/components/my-investment-dashboard-v2/my-personal-balance-view-model-v2';

@Component({
  name: 'MyPersonalBalanceCard',
  components: {
    LoadingDataCard,
  },
})
export default class MyPersonalBalanceCard extends Vue {
  my_personal_balance_view_model_v2 = Vue.observable(new MyPersonalBalanceViewModelV2());
}
