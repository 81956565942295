import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';
import { currencyFormat } from '@/vue-app/utils/currency';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { StateManager as AllianzAccountStateManager } from '@/modules/my-investment/allianz/allianz-account/domain/state/state-manager';
import { StateManager as AllianzAccountReportStateManager } from '@/modules/my-investment/allianz/allianz-account-report/domain/state/state-manager';
import { StateManager as KuspitAccountStateManager } from '@/modules/my-investment/kuspit/customer-has-account/domain/state-manager';
import { StateManager as KuspitPositionStateManager } from '@/modules/my-investment/kuspit/customer-kuspit-position/domain/state/state-manager';

export default class MyPersonalBalanceViewModelV2 {
  @Inject(TYPES.ALLIANZ_ACCOUNT_STATE_MANAGER)
  private readonly allianz_account_state_manager!: AllianzAccountStateManager;

  @Inject(TYPES.ALLIANZ_ACCOUNT_REPORT_STATE_MANAGER)
  private readonly allianz_account_report_state_manager!: AllianzAccountReportStateManager;

  @Inject(TYPES.CUSTOMER_KUSPIT_POSITION_STATE_MANAGER)
  private readonly kuspit_position_state_manager!: KuspitPositionStateManager;

  @Inject(TYPES.CUSTOMER_HAS_KUSPIT_ACCOUNT_STATE_MANAGER)
  private readonly kuspit_account_state_manager!: KuspitAccountStateManager;

  readonly i18n_namespace = 'components.my-investment-dashboard.my-personal-balance';

  get is_loading() {
    return this.allianz_account_state_manager.state.is_loading
      || this.allianz_account_report_state_manager.state.is_loading
      || this.kuspit_account_state_manager.state.is_loading
      || this.kuspit_position_state_manager.state.is_loading;
  }

  get has_allianz_account() {
    return this.allianz_account_state_manager.state.account.id
      && this.allianz_account_state_manager.state.loaded;
  }

  get has_kuspit_account() {
    return this.kuspit_account_state_manager.state.has_account.has_account
      && this.kuspit_account_state_manager.state.has_account_loaded;
  }

  get customer_has_investment_product() {
    return this.allianz_account_state_manager.state.account.id
      || (this.kuspit_account_state_manager.state.has_account.has_account
      && this.kuspit_account_state_manager.state.has_account_loaded);
  }

  get balance() {
    let kuspit_balance = 0;
    if (this.kuspit_position_state_manager.state.position.position) {
      kuspit_balance = parseFloat(this.kuspit_position_state_manager.state.position.position);
    }
    const allianz_balance = this.allianz_account_report_state_manager.state.items.reduce(
      (total, current) => total + current.final_balance_mxn!, 0,
    );
    return `${currencyFormat(allianz_balance + kuspit_balance)} MXN`;
  }

  translate = (value: string) => (
    i18n.t(`${this.i18n_namespace}.${value}`) as string
  )
}
